import React, { useContext } from 'react';
import Waiting from '../../components/Waiting';
import { ScreenEnum } from '../../enums';
import { useTranslation } from 'react-i18next';
import { SoftwareCheckProps } from './interfaces';
import AppStateContext from '../../AppStateProvider/context';

export default function SoftwareCheck(props: SoftwareCheckProps) {
	const { t } = useTranslation();
	const { state } = useContext(AppStateContext);

	let translations = {
		headline: t('SoftwareCheckHeadline'),
		text: t('SoftwareCheckText'),
	};

	// remove for real checks in apk
	setTimeout(() => props.setScreen(ScreenEnum.selectBrand), 2000);

	return (
		<>
			<div className='top'>
				<div className='software-check'>
					{props.backBtn(
						state.apk
							? ScreenEnum.setSecond
							: ScreenEnum.hardwareCheck
					)}
					<Waiting
						screen={ScreenEnum.softwareCheck}
						setScreen={props.setScreen}
						headline={translations?.headline}
						text={translations?.text}
					/>
					{props.warning}
				</div>
			</div>
		</>
	);
}
